import type { RouterOptions } from '@nuxt/schema'
import { useMyContactFormStore } from '~/store/storeContactForm'
import { usePagePositionStore } from '~/store/storePagePosition'



export default <RouterOptions>{
    scrollBehavior(to, from, savedPosition) {

        console.log('savedPosition', savedPosition)
        
        if (!savedPosition && !to.meta.saveScrollPosition)
            return { top: 0 }

        const { isHydrating, payload } = useNuxtApp()
        console.log('isHydrating', isHydrating, 'payload', payload.serverRendered)
        
        if (isHydrating && payload.serverRendered)
            return savedPosition || {top: 0}

        

        
        const pagePosition = usePagePositionStore().getSavedPosition(to.name?.toString())

        // console.log('scrollBehavior', performance.now())
        
        return new Promise(async (resolve) => {
           
            let decr = 1
            const { hooks } = useNuxtApp()
            // console.log('scrollBehavior promise', performance.now())
            hooks.hookOnce('page:finish', async () => {
                console.log('scrollBehavior page:finish', decr, performance.now())
                if (decr === 1) {
                    decr = 2
                    await nextTick()
                    console.log('scrollBehavior hook', 'savedPosition', savedPosition, 'savedPagePosition', pagePosition, to.name, from.name, decr, performance.now())
                    resolve(savedPosition || to.meta.saveScrollPosition ? pagePosition || { top: 0 } : { top: 0 })
                }

            })
        })
    }
}
