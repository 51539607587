import type { ScrollDirection } from "~/utils/injectionSymbol";


export default defineNuxtPlugin((nuxtApp) => {
    interface ScrollConfig {
        minDistance: number;
        minVelocity: number;
    }

    const downConfig: ScrollConfig = { minDistance: 200, minVelocity: 0.7 }
    const upConfig: ScrollConfig = { minDistance: 200, minVelocity: 0.4 }

    const router = useRouter();
    const scrollDirection = ref<'up' | 'down' | null>(null);
    const scrollDown = ref(false);
    const isAtTop = ref(true);
    const velocity = ref(0);

    if (import.meta.client) {
        let lastScrollTop = window.scrollY;
        let lastDirectionChangeY = lastScrollTop; // Stocke la position où la direction a changé
        let lastTimestamp = performance.now();
        let canHandleScroll = true;

        const handleScroll = () => {
            if (!canHandleScroll) {
                canHandleScroll = true;
                return;
            }

            const currentScrollTop = window.scrollY;
            const currentTimestamp = performance.now();

            const deltaY = currentScrollTop - lastScrollTop;
            const deltaTime = currentTimestamp - lastTimestamp;

            if (deltaTime === 0) return; // Pour éviter la division par zéro si deltaTime est nul

            // Calcul de la vélocité en pixels par milliseconde
            const currentVelocity = Math.abs(deltaY) / deltaTime;

            // Si on est au sommet, on réinitialise les valeurs
            if (currentScrollTop === 0) {
                isAtTop.value = true;
                scrollDirection.value = null;
                scrollDown.value = false;
                velocity.value = 0;
                lastDirectionChangeY = currentScrollTop; // Réinitialiser la position de départ
            } else {
                isAtTop.value = false;

                // Vérifier si l'utilisateur est en bas de la page
                const maxScrollTop = document.documentElement.scrollHeight - window.innerHeight;
                if (currentScrollTop >= maxScrollTop) {

                    // L'utilisateur est en bas de la page, on déclenche un changement de direction
                    scrollDirection.value = 'up';
                    scrollDown.value = false;
                    velocity.value = currentVelocity;
                    return; // On sort ici, car on ne veut pas exécuter la logique du défilement
                }

                // Vérifier si la direction a changé
                if ((deltaY > 0 && scrollDirection.value === 'up') || (deltaY < 0 && scrollDirection.value === 'down')) {
                    lastDirectionChangeY = currentScrollTop;
                    velocity.value = 0; // Réinitialiser la vélocité
                    scrollDirection.value = null; // Réinitialiser la direction temporairement
                }

                // Détection de scroll vers le bas
                if (deltaY > 0) {
                    if (
                        Math.abs(currentScrollTop - lastDirectionChangeY) > downConfig.minDistance ||
                        currentVelocity > downConfig.minVelocity
                    ) {
                        scrollDirection.value = 'down';
                        scrollDown.value = true;
                        velocity.value = currentVelocity;
                    }
                }
                // Détection de scroll vers le haut
                else if (deltaY < 0) {
                    if (
                        Math.abs(currentScrollTop - lastDirectionChangeY) > upConfig.minDistance ||
                        currentVelocity > upConfig.minVelocity
                    ) {
                        scrollDirection.value = 'up';
                        scrollDown.value = false;
                        velocity.value = currentVelocity;
                    }
                }
            }

            lastScrollTop = currentScrollTop;
            lastTimestamp = currentTimestamp;
        };


        // Reset les valeurs après navigation
        const remove = router.afterEach(() => {
            //console.log('afterEach', window.scrollY, performance.now());
            scrollDirection.value = null;
            scrollDown.value = false;
            velocity.value = 0;
            lastDirectionChangeY = window.scrollY;
            lastScrollTop = window.scrollY;
            canHandleScroll = false;
            isAtTop.value = window.scrollY === 0;
            setTimeout(() => {
                //console.log('afterEach timeout', window.scrollY, performance.now());
                isAtTop.value = window.scrollY === 0;
            }, 50);
        });


        const { hooks } = useNuxtApp()
        hooks.hook('app:mounted', async () => {
            window.addEventListener('scroll', handleScroll, { passive: true });
            //console.log('scrollBehavior hook', performance.now())
        })
        nuxtApp.vueApp.onUnmount(() => {
            remove();
            window.removeEventListener('scroll', handleScroll);
        })
    }

    nuxtApp.vueApp.provide(injectionSymbolScrollDirection, { scrollDirection, isAtTop, scrollDown, velocity });
    //return { scrollDirection, scrollDown, velocity, isAtTop };


})
