<template>
  <!-- <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">

  <Head>

    <template v-for="link in head.link" :key="link.id">
      <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
    </template>
<template v-for="meta in head.meta" :key="meta.id">
      <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
    </template>
</Head> -->

  <!-- <Body> -->
  <UApp :toaster="{position: 'top-center'}" :locale="locales[locale as 'fr' |'en']" class="">
  <UProgress color="lolo" :max="100" :model-value="progress" size="sm" class="fixed top-0 inset-x-0 z-50"></UProgress>
  <NuxtLayout>
    <NuxtLayout :name="route.meta.showBreadcrumb ?'route-path': 'empty'">
      <NuxtPage />
    </NuxtLayout>
  </NuxtLayout>
</UApp>
  <!-- </Body> -->

  <!-- </Html> -->

</template>
<style></style>
<script setup lang="ts">
import { fr, en } from '@nuxt/ui/locale'
const locales = {fr, en}

const route = useRoute()
const { t, locale } = useI18n()
locale.value
useSeoMeta({
  title: t('titre'),
  applicationName: 'Air&Montagne',
  description: t('description'),
})

const localeHead = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})

const head = useHead({
  htmlAttrs: {
    lang: localeHead.value.htmlAttrs.lang,
    dir: localeHead.value.htmlAttrs.dir,
    class: 'font-nunito'
  },
  
  link: [...(localeHead.value.link || [])],
  meta: [...(localeHead.value.meta || [])],
})


const {progress, set, clear, error, finish, isLoading, start} = useLoadingIndicator()

</script>
