import { default as forgot_45passwordOZnQ9ajrM1Meta } from "/opt/buildhome/repo/pages/(auth)/(password)/forgot-password.vue?macro=true";
import { default as reset_45password2E3r9qa9cfMeta } from "/opt/buildhome/repo/pages/(auth)/(password)/reset-password.vue?macro=true";
import { default as sign_45inNgbTQQkQApMeta } from "/opt/buildhome/repo/pages/(auth)/(password)/sign-in.vue?macro=true";
import { default as sign_45upvof3EFkeE7Meta } from "/opt/buildhome/repo/pages/(auth)/(password)/sign-up.vue?macro=true";
import { default as webauthnNl7asz9hdqMeta } from "/opt/buildhome/repo/pages/(auth)/(sign-in)/webauthn.vue?macro=true";
import { default as indexfQDeoyx9sDMeta } from "/opt/buildhome/repo/pages/(galerie)/galerie/index.vue?macro=true";
import { default as galerie4deRMIVDauMeta } from "/opt/buildhome/repo/pages/(galerie)/galerie.vue?macro=true";
import { default as indexls7occOYWVMeta } from "/opt/buildhome/repo/pages/(user)/user/index.vue?macro=true";
import { default as planningsVyJwqEkVdMeta } from "/opt/buildhome/repo/pages/(user)/user/planning.vue?macro=true";
import { default as userFR7HLSEblhMeta } from "/opt/buildhome/repo/pages/(user)/user.vue?macro=true";
import { default as aboutcGvMViY0SnMeta } from "/opt/buildhome/repo/pages/about.vue?macro=true";
import { default as indextyejo9uCEPMeta } from "/opt/buildhome/repo/pages/activites/index.vue?macro=true";
import { default as parapentehZVuSAfPMcMeta } from "/opt/buildhome/repo/pages/activites/parapente.vue?macro=true";
import { default as indexkHcL9o5WiwMeta } from "/opt/buildhome/repo/pages/activites/ski/index.vue?macro=true";
import { default as indexHCV7buEivkMeta } from "/opt/buildhome/repo/pages/activites/ski/randonnee/index.vue?macro=true";
import { default as randonneebtovDj6V0yMeta } from "/opt/buildhome/repo/pages/activites/ski/randonnee.vue?macro=true";
import { default as ski4wgpTswnlDMeta } from "/opt/buildhome/repo/pages/activites/ski.vue?macro=true";
import { default as activitesGTC3t6JkvIMeta } from "/opt/buildhome/repo/pages/activites.vue?macro=true";
import { default as articlesrUijrSIq5yMeta } from "/opt/buildhome/repo/pages/articles.vue?macro=true";
import { default as cartltOYTvmzvgMeta } from "/opt/buildhome/repo/pages/cart.vue?macro=true";
import { default as conditionsN06e2dG6S9Meta } from "/opt/buildhome/repo/pages/conditions.vue?macro=true";
import { default as confidentialite6PEsCfFDVUMeta } from "/opt/buildhome/repo/pages/confidentialite.vue?macro=true";
import { default as contactupLWW5VsuQMeta } from "/opt/buildhome/repo/pages/contact.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as reserverJZaHHVOftpMeta } from "/opt/buildhome/repo/pages/reserver.vue?macro=true";
import { default as uploadImages88pCRhuPloMeta } from "/opt/buildhome/repo/pages/uploadImages.vue?macro=true";
export default [
  {
    name: "forgot-password___fr___default",
    path: "/forgot-password",
    meta: forgot_45passwordOZnQ9ajrM1Meta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/forgot-password.vue")
  },
  {
    name: "forgot-password___fr",
    path: "/fr/forgot-password",
    meta: forgot_45passwordOZnQ9ajrM1Meta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/forgot-password.vue")
  },
  {
    name: "forgot-password___en",
    path: "/en/forgot-password",
    meta: forgot_45passwordOZnQ9ajrM1Meta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/forgot-password.vue")
  },
  {
    name: "reset-password___fr___default",
    path: "/reset-password",
    meta: reset_45password2E3r9qa9cfMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/reset-password.vue")
  },
  {
    name: "reset-password___fr",
    path: "/fr/reset-password",
    meta: reset_45password2E3r9qa9cfMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/reset-password.vue")
  },
  {
    name: "reset-password___en",
    path: "/en/reset-password",
    meta: reset_45password2E3r9qa9cfMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/reset-password.vue")
  },
  {
    name: "sign-in___fr___default",
    path: "/sign-in",
    meta: sign_45inNgbTQQkQApMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/sign-in.vue")
  },
  {
    name: "sign-in___fr",
    path: "/fr/sign-in",
    meta: sign_45inNgbTQQkQApMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/sign-in.vue")
  },
  {
    name: "sign-in___en",
    path: "/en/sign-in",
    meta: sign_45inNgbTQQkQApMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/sign-in.vue")
  },
  {
    name: "sign-up___fr___default",
    path: "/sign-up",
    meta: sign_45upvof3EFkeE7Meta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/sign-up.vue")
  },
  {
    name: "sign-up___fr",
    path: "/fr/sign-up",
    meta: sign_45upvof3EFkeE7Meta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/sign-up.vue")
  },
  {
    name: "sign-up___en",
    path: "/en/sign-up",
    meta: sign_45upvof3EFkeE7Meta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/sign-up.vue")
  },
  {
    name: "webauthn___fr___default",
    path: "/webauthn",
    component: () => import("/opt/buildhome/repo/pages/(auth)/(sign-in)/webauthn.vue")
  },
  {
    name: "webauthn___fr",
    path: "/fr/webauthn",
    component: () => import("/opt/buildhome/repo/pages/(auth)/(sign-in)/webauthn.vue")
  },
  {
    name: "webauthn___en",
    path: "/en/webauthn",
    component: () => import("/opt/buildhome/repo/pages/(auth)/(sign-in)/webauthn.vue")
  },
  {
    name: galerie4deRMIVDauMeta?.name,
    path: "/galerie",
    component: () => import("/opt/buildhome/repo/pages/(galerie)/galerie.vue"),
    children: [
  {
    name: "galerie___fr___default",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(galerie)/galerie/index.vue")
  }
]
  },
  {
    name: galerie4deRMIVDauMeta?.name,
    path: "/fr/galerie",
    component: () => import("/opt/buildhome/repo/pages/(galerie)/galerie.vue"),
    children: [
  {
    name: "galerie___fr",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(galerie)/galerie/index.vue")
  }
]
  },
  {
    name: galerie4deRMIVDauMeta?.name,
    path: "/en/gallery",
    component: () => import("/opt/buildhome/repo/pages/(galerie)/galerie.vue"),
    children: [
  {
    name: "galerie___en",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(galerie)/galerie/index.vue")
  }
]
  },
  {
    name: userFR7HLSEblhMeta?.name,
    path: "/user",
    meta: userFR7HLSEblhMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user.vue"),
    children: [
  {
    name: "user___fr___default",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(user)/user/index.vue")
  },
  {
    name: "user-planning___fr___default",
    path: "planning",
    meta: planningsVyJwqEkVdMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user/planning.vue")
  }
]
  },
  {
    name: userFR7HLSEblhMeta?.name,
    path: "/fr/user",
    meta: userFR7HLSEblhMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user.vue"),
    children: [
  {
    name: "user___fr",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(user)/user/index.vue")
  },
  {
    name: "user-planning___fr",
    path: "planning",
    meta: planningsVyJwqEkVdMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user/planning.vue")
  }
]
  },
  {
    name: userFR7HLSEblhMeta?.name,
    path: "/en/user",
    meta: userFR7HLSEblhMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user.vue"),
    children: [
  {
    name: "user___en",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(user)/user/index.vue")
  },
  {
    name: "user-planning___en",
    path: "planning",
    meta: planningsVyJwqEkVdMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user/planning.vue")
  }
]
  },
  {
    name: "about___fr___default",
    path: "/about",
    meta: aboutcGvMViY0SnMeta || {},
    component: () => import("/opt/buildhome/repo/pages/about.vue")
  },
  {
    name: "about___fr",
    path: "/fr/about",
    meta: aboutcGvMViY0SnMeta || {},
    component: () => import("/opt/buildhome/repo/pages/about.vue")
  },
  {
    name: "about___en",
    path: "/en/about",
    meta: aboutcGvMViY0SnMeta || {},
    component: () => import("/opt/buildhome/repo/pages/about.vue")
  },
  {
    name: activitesGTC3t6JkvIMeta?.name,
    path: "/activites",
    meta: activitesGTC3t6JkvIMeta || {},
    component: () => import("/opt/buildhome/repo/pages/activites.vue"),
    children: [
  {
    name: "activites___fr___default",
    path: "",
    meta: indextyejo9uCEPMeta || {},
    component: () => import("/opt/buildhome/repo/pages/activites/index.vue")
  },
  {
    name: "activites-parapente___fr___default",
    path: "parapente",
    meta: parapentehZVuSAfPMcMeta || {},
    component: () => import("/opt/buildhome/repo/pages/activites/parapente.vue")
  },
  {
    name: ski4wgpTswnlDMeta?.name,
    path: "ski",
    meta: ski4wgpTswnlDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/activites/ski.vue"),
    children: [
  {
    name: "activites-ski___fr___default",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/activites/ski/index.vue")
  },
  {
    name: randonneebtovDj6V0yMeta?.name,
    path: "randonnee",
    meta: randonneebtovDj6V0yMeta || {},
    component: () => import("/opt/buildhome/repo/pages/activites/ski/randonnee.vue"),
    children: [
  {
    name: "activites-ski-randonnee___fr___default",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/activites/ski/randonnee/index.vue")
  }
]
  }
]
  }
]
  },
  {
    name: activitesGTC3t6JkvIMeta?.name,
    path: "/fr/activites",
    meta: activitesGTC3t6JkvIMeta || {},
    component: () => import("/opt/buildhome/repo/pages/activites.vue"),
    children: [
  {
    name: "activites___fr",
    path: "",
    meta: indextyejo9uCEPMeta || {},
    component: () => import("/opt/buildhome/repo/pages/activites/index.vue")
  },
  {
    name: "activites-parapente___fr",
    path: "parapente",
    meta: parapentehZVuSAfPMcMeta || {},
    component: () => import("/opt/buildhome/repo/pages/activites/parapente.vue")
  },
  {
    name: ski4wgpTswnlDMeta?.name,
    path: "ski",
    meta: ski4wgpTswnlDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/activites/ski.vue"),
    children: [
  {
    name: "activites-ski___fr",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/activites/ski/index.vue")
  },
  {
    name: randonneebtovDj6V0yMeta?.name,
    path: "randonnee",
    meta: randonneebtovDj6V0yMeta || {},
    component: () => import("/opt/buildhome/repo/pages/activites/ski/randonnee.vue"),
    children: [
  {
    name: "activites-ski-randonnee___fr",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/activites/ski/randonnee/index.vue")
  }
]
  }
]
  }
]
  },
  {
    name: activitesGTC3t6JkvIMeta?.name,
    path: "/en/activities",
    meta: activitesGTC3t6JkvIMeta || {},
    component: () => import("/opt/buildhome/repo/pages/activites.vue"),
    children: [
  {
    name: "activites___en",
    path: "",
    meta: indextyejo9uCEPMeta || {},
    component: () => import("/opt/buildhome/repo/pages/activites/index.vue")
  },
  {
    name: "activites-parapente___en",
    path: "paragliding",
    meta: parapentehZVuSAfPMcMeta || {},
    component: () => import("/opt/buildhome/repo/pages/activites/parapente.vue")
  },
  {
    name: ski4wgpTswnlDMeta?.name,
    path: "ski",
    meta: ski4wgpTswnlDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/activites/ski.vue"),
    children: [
  {
    name: "activites-ski___en",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/activites/ski/index.vue")
  },
  {
    name: randonneebtovDj6V0yMeta?.name,
    path: "randonnee",
    meta: randonneebtovDj6V0yMeta || {},
    component: () => import("/opt/buildhome/repo/pages/activites/ski/randonnee.vue"),
    children: [
  {
    name: "activites-ski-randonnee___en",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/activites/ski/randonnee/index.vue")
  }
]
  }
]
  }
]
  },
  {
    name: "articles___fr___default",
    path: "/articles",
    component: () => import("/opt/buildhome/repo/pages/articles.vue")
  },
  {
    name: "articles___fr",
    path: "/fr/articles",
    component: () => import("/opt/buildhome/repo/pages/articles.vue")
  },
  {
    name: "articles___en",
    path: "/en/articles",
    component: () => import("/opt/buildhome/repo/pages/articles.vue")
  },
  {
    name: "cart___fr___default",
    path: "/panier",
    component: () => import("/opt/buildhome/repo/pages/cart.vue")
  },
  {
    name: "cart___fr",
    path: "/fr/panier",
    component: () => import("/opt/buildhome/repo/pages/cart.vue")
  },
  {
    name: "cart___en",
    path: "/en/cart",
    component: () => import("/opt/buildhome/repo/pages/cart.vue")
  },
  {
    name: "conditions___fr___default",
    path: "/conditions",
    component: () => import("/opt/buildhome/repo/pages/conditions.vue")
  },
  {
    name: "conditions___fr",
    path: "/fr/conditions",
    component: () => import("/opt/buildhome/repo/pages/conditions.vue")
  },
  {
    name: "conditions___en",
    path: "/en/conditions",
    component: () => import("/opt/buildhome/repo/pages/conditions.vue")
  },
  {
    name: "confidentialite___fr___default",
    path: "/confidentialite",
    component: () => import("/opt/buildhome/repo/pages/confidentialite.vue")
  },
  {
    name: "confidentialite___fr",
    path: "/fr/confidentialite",
    component: () => import("/opt/buildhome/repo/pages/confidentialite.vue")
  },
  {
    name: "confidentialite___en",
    path: "/en/confidentialite",
    component: () => import("/opt/buildhome/repo/pages/confidentialite.vue")
  },
  {
    name: "contact___fr___default",
    path: "/nous-contacter",
    component: () => import("/opt/buildhome/repo/pages/contact.vue")
  },
  {
    name: "contact___fr",
    path: "/fr/nous-contacter",
    component: () => import("/opt/buildhome/repo/pages/contact.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/opt/buildhome/repo/pages/contact.vue")
  },
  {
    name: "index___fr___default",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "reserver___fr___default",
    path: "/reserver",
    meta: reserverJZaHHVOftpMeta || {},
    component: () => import("/opt/buildhome/repo/pages/reserver.vue")
  },
  {
    name: "reserver___fr",
    path: "/fr/reserver",
    meta: reserverJZaHHVOftpMeta || {},
    component: () => import("/opt/buildhome/repo/pages/reserver.vue")
  },
  {
    name: "reserver___en",
    path: "/en/book",
    meta: reserverJZaHHVOftpMeta || {},
    component: () => import("/opt/buildhome/repo/pages/reserver.vue")
  },
  {
    name: "uploadImages___fr___default",
    path: "/uploadImages",
    component: () => import("/opt/buildhome/repo/pages/uploadImages.vue")
  },
  {
    name: "uploadImages___fr",
    path: "/fr/uploadImages",
    component: () => import("/opt/buildhome/repo/pages/uploadImages.vue")
  },
  {
    name: "uploadImages___en",
    path: "/en/uploadImages",
    component: () => import("/opt/buildhome/repo/pages/uploadImages.vue")
  }
]